import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'

const previewPage = () => {
  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: 'sigmawebsite',
  })

  useEffect(() => {
    // Define url
    const url = `/${(previewData as any)?.prismicContent?.data?.preview_page?.slug}`;
    
    // If this is not a preview, skip.  
    if (isPreview === false) { return }    

    window.__PRISMIC_PREVIEW_DATA__ = previewData

    // Navigate to the document's page    
    if (url !== undefined) {
      if (!url.includes('/undefined')) {
        navigate(url)
      }
    }

  }, [isPreview, previewData, path])

  // Tell user if this is not a preview
  if (isPreview === false) { return <div>Not a preview!</div> }

  return (
    <div className="fx-l-preview-page">
      <div className="fx-container">
        <div className="fx-layout">
          <div className="fx-layout-col-mobile--24">
            <div className="fx-l-preview-page-body">
              <h1 className="fx-l-preview-page-body-header"><div>Loading preview...</div></h1>
              <p className="fx-l-preview-page-body-message">This could take a couple seconds.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default previewPage